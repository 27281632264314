<template>
  <div
    class="activity-detail container van-tab__panel">
     <h2 class="txt-center">{{activityDetail.name}}</h2>
     <p class="fz12 default2 summary"> <b v-if="activityDetail.start_time">{{activityDetail.process_msg}} </b> <em style="margin-left:10px;"> {{$t('public.acTime')}}: {{activityDetail.start_time}} </em> <br> {{activityDetail.summary}}</p>
     <div v-html="activityDetail.description"></div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { getActivityDetail } from "@/api/public";
import { useRouter } from 'vue-router'

const router = useRouter()
const { name }= router.currentRoute.value.query
const activityDetail=ref({})
getActivityDetail(name).then(res=>{
activityDetail.value=res.data;
})
</script>

<style lang="less" scoped>
.activity-detail  {
  .summary{
    padding: 10px;
    line-height: 23px;
  }
}
</style>
